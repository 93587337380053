<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.name"
      :rules="rules.name"
      name="name"
      label="Name"
    />

    <vx-input
      v-model="formValue.description"
      :rules="rules.description"
      name="description"
      label="description"
    />

    <vx-select
      v-model="formValue.type"
      :rules="rules.type"
      :options="typesValues"
      name="type"
      label="Type"
    />

    <vx-input
      v-model="formValue.value"
      :rules="rules.value"
      name="value"
      label="Value"
    />

    <vx-select-resource
      v-model="formValue.currency_code"
      :rules="rules.currency_code"
      :resource="currenciesResource"
      :reduce="({ code }) => code"
      options-label="code"
      name="currency_code"
      label="Currency"
    />

    <vx-input
      v-model="formValue.min_refill_amount"
      :rules="rules.min_refill_amount"
      name="min_refill_amount"
      label="Min Refill Sum"
    />

    <vx-input
      v-model="formValue.max_usage_count"
      :rules="rules.max_usage_count"
      name="max_usage_count"
      label="Max Usage Count"
    />

    <vx-input
      v-model="days"
      :rules="rules.days"
      name="days"
      label="Bonus Lifetime Days"
    />

    <vx-date-picker
      v-model="time"
      name="days"
      label="Bonus Lifetime"
      type="time"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { reactive, computed, ref } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelect, VxSelectResource, VxDatePicker } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, bonuses, bonusesTypes, currencies } from '@/services/resources'
import { apiDefaultValidators, frontDefaultValidators } from '@/services/form/validationService'
import {
  daysToSeconds,
  formatFromSecondsToHours,
  hoursToDays,
  secondsToTime,
  timeToSeconds,
  formatToCents,
  formatToDecimal
} from '@/services/utils'

export default {
  name: 'RefillBonusEditor',
  components: {
    VxForm,
    VxInput,
    VxSelect,
    VxSelectResource,
    VxButton,
    VxDatePicker,
    BModal
  },
  props: {
    bonus: {
      type: Object,
      default: null
    }
  },
  setup ({ bonus }, { emit }) {
    const days = ref(0)
    const time = ref(null)

    const typesValues = computed(() => Object.values(bonusesTypes))

    const isEdit = !!bonus

    const currenciesResource = currencies.getAll

    const frontToBackMapper = (bonus) => {
      const { min_refill_amount, value, type } = bonus
      const lifetime = calcLifetime()

      return {
        ...bonus,
        lifetime: lifetime !== 0 ? lifetime : null,
        min_refill_amount: formatToCents(min_refill_amount),
        value: type === bonusesTypes.fixed ? formatToCents(value) : value
      }
    }

    const backToFrontMapper = (bonus) => {
      const { min_refill_amount, value, type, lifetime } = bonus
      const lifetimeHours = formatFromSecondsToHours(lifetime)
      days.value = hoursToDays(lifetimeHours)
      time.value = secondsToTime(lifetime)

      return {
        ...bonus,
        min_refill_amount: formatToDecimal(min_refill_amount),
        value: type === bonusesTypes.fixed ? formatToDecimal(value) : value
      }
    }

    const calcLifetime = () => {
      if (time.value) {
        return timeToSeconds(time.value) + daysToSeconds(days.value)
      } else {
        return daysToSeconds(days.value)
      }
    }

    const formResource = isEdit
      ? passDataToResource(bonuses.refillUpdate, {
        frontToBackMapper,
        requestParams: { urlParams: { id: bonus.id } }
      })
      : passDataToResource(bonuses.refillCreate, {
        frontToBackMapper
      })

    const formValue = reactive({
      name: '',
      description: '',
      type: '',
      value: 1,
      currency_code: 'USD',
      min_refill_amount: 0,
      max_usage_count: null,
      lifetime: null
    })

    const validationValue = {
      [bonusesTypes.fixed]: {
        min_value: 1,
        max_value: 10000
      },
      [bonusesTypes.percentage]: {
        min_value: 0.01
      }
    }

    const rules = {
      name: {
        ...frontDefaultValidators.string,
        min: 2,
        max: apiDefaultValidators.maxTinyTextLength
      },
      description: {
        min: 2,
        max: apiDefaultValidators.maxTinyTextLength
      },
      type: {
        required: true
      },
      value: {
        required: true,
        ...validationValue[formValue.type]
      },
      currency_code: {
        required: true
      },
      min_refill_amount: {
        required: true,
        decimal: 2,
        min_value: 0,
        max_value: 1000
      },
      max_usage_count: {
        required: true,
        min_value: 1,
        max_value: 10000000
      },
      days: {
        required: true
      }
    }

    if (isEdit) {
      setValuesToForm(backToFrontMapper(bonus), formValue)
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      currenciesResource,
      rules,
      typesValues,
      days,
      time,

      handleSubmit,
      bonusesTypes
    }
  }
}
</script>
