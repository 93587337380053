<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :resource="resource"
      :filters="filters"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <refill-bonus-editor :bonus="selectedBonus" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxTable,
  VxCellDate,
  VxCellButtons,
  VxCellBadges,
  VxCellMoney
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import RefillBonusEditor from '@/views/bonuses/RefillBonusEditor'
import { VxButton } from '@/components/buttons'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { bonuses, useResource, bonusesTypes } from '@/services/resources'
import { sortOrders } from '@/services/table'
import { ref, computed } from '@vue/composition-api'
import { BModal } from 'bootstrap-vue'
import {
  addPercentSign,
  secondsToDays,
  secondsToTime,
  moneyFormat
} from '@/services/utils'

export default {
  name: 'RefillBonuses',

  components: {
    VxTable,
    VxButton,
    VxPageTitleActions,
    VxCellDate,
    VxCellButtons,
    VxCellMoney,
    RefillBonusEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)

    const resource = bonuses.refill

    const { can: canCreate } = useResource(bonuses.refillCreate)
    const { can: canUpdate } = useResource(bonuses.refillUpdate)

    const modalIsOpen = ref(false)
    const selectedBonus = ref(null)
    const modalTitle = computed(() => {
      return (selectedBonus.value ? 'Edit' : 'Create') + ' Bonus'
    })

    const openModal = (bonus = null) => {
      selectedBonus.value = bonus
      modalIsOpen.value = true
    }

    const closeModal = () => {
      modalIsOpen.value = false
      selectedBonus.value = null
    }

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const columns = [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true
      },
      {
        key: 'description',
        label: 'Description'
      },
      {
        key: 'type',
        label: 'Type',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [bonusesTypes.fixed]: 'info',
            [bonusesTypes.percentage]: 'primary'
          }
        }
      },
      {
        key: 'value',
        label: 'Value',
        formatter: (value, key, item) => {
          return item.type === bonusesTypes.fixed
            ? moneyFormat({ amount: value, currency: item.currency_code })
            : addPercentSign(value)
        }
      },
      {
        key: 'min_refill_amount',
        label: 'Min Refill Amount',
        component: VxCellMoney
      },
      {
        key: 'max_usage_count',
        label: 'Max Usage Count'
      },
      {
        key: 'lifetime',
        label: 'Bonus Lifetime',
        formatter: (value) => {
          return value
            ? `${secondsToDays(value)} ${secondsToTime(value)}`
            : null
        }
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: {
          buttons: [
            {
              ...buttons.edit,
              can: canUpdate,
              onClick: openModal
            }
          ]
        }
      },
      {
        key: 'created_at',
        label: 'Created At',
        component: VxCellDate
      }
    ]
    const filters = [
      {
        key: 'id',
        label: 'ID'
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      filters,
      canCreate,

      selectedBonus,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
